import React from 'react';
import { graphql } from 'gatsby';

import PageHero from 'src/components/content/PageHero';
import MarkdownSectionsNavigator from 'src/components/markdown/MarkdownSectionsNavigator';
import { useLocalization } from 'src/providers/LocalizationProvider';
import DataProvider from 'src/providers/DataProvider';

export default function CottonPage({ data }) {
  const {
    allFile: { nodes: heroImages },
  } = data;

  const { page, t } = useLocalization();

  return (
    <DataProvider>
      <PageHero
        focusColor="brand.membership"
        textColor="common.white"
        images={heroImages}
        text={t('hero.membership')}
      />
      <MarkdownSectionsNavigator page={page} focusColor="brand.membership" />
    </DataProvider>
  );
}

export const query = graphql`
  query MembershipPage {
    allFile(
      limit: 2
      filter: { relativePath: { regex: "/hero/membership(.*)/i" } }
    ) {
      nodes {
        childImageSharp {
          fluid(maxHeight: 280, maxWidth: 380) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
